/* eslint-disable */ // Auto-generated file (DO NOT EDIT!!!), refer gql-codegen.yml

import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver, StorageAdapter as GraphCacheStorageAdapter } from '@urql/exchange-graphcache';
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Admin = {
  __typename?: 'Admin';
  admins: Array<User>;
  allTeams: Array<Team>;
  allUsers: Array<User>;
  collectionCountInTeam: Scalars['Int'];
  environmentCountInTeam: Scalars['Int'];
  invitedUsers: Array<InvitedUser>;
  membersCountInTeam: Scalars['Int'];
  pendingInvitationCountInTeam: Array<TeamInvitation>;
  requestCountInTeam: Scalars['Int'];
  teamCollectionsCount: Scalars['Int'];
  teamInfo: Team;
  teamRequestsCount: Scalars['Int'];
  teamsCount: Scalars['Int'];
  userInfo: User;
  usersCount: Scalars['Int'];
};


export type AdminAllTeamsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AdminAllUsersArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AdminCollectionCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminEnvironmentCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminMembersCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminPendingInvitationCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminRequestCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminTeamInfoArgs = {
  teamID: Scalars['ID'];
};


export type AdminUserInfoArgs = {
  userUid: Scalars['ID'];
};

export type CollectionReorderData = {
  __typename?: 'CollectionReorderData';
  collection: TeamCollection;
  nextCollection?: Maybe<TeamCollection>;
};

export type CreateTeamRequestInput = {
  request: Scalars['String'];
  teamID: Scalars['ID'];
  title: Scalars['String'];
};

export type InvitedUser = {
  __typename?: 'InvitedUser';
  adminEmail: Scalars['String'];
  adminUid: Scalars['ID'];
  invitedOn: Scalars['DateTime'];
  inviteeEmail: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTeamInvitation: TeamMember;
  addUserToTeamByAdmin: TeamMember;
  changeUserRoleInTeamByAdmin: TeamMember;
  clearGlobalEnvironments: UserEnvironment;
  createChildCollection: TeamCollection;
  createDuplicateEnvironment: TeamEnvironment;
  createGQLChildUserCollection: UserCollection;
  createGQLRootUserCollection: UserCollection;
  createGQLUserRequest: UserRequest;
  createRESTChildUserCollection: UserCollection;
  createRESTRootUserCollection: UserCollection;
  createRESTUserRequest: UserRequest;
  createRequestInCollection: TeamRequest;
  createRootCollection: TeamCollection;
  createShortcode: Shortcode;
  createTeam: Team;
  createTeamByAdmin: Team;
  createTeamEnvironment: TeamEnvironment;
  createTeamInvitation: TeamInvitation;
  createUserEnvironment: UserEnvironment;
  createUserGlobalEnvironment: UserEnvironment;
  createUserHistory: UserHistory;
  createUserSettings: UserSettings;
  deleteAllUserHistory: UserHistoryDeletedManyData;
  deleteAllVariablesFromTeamEnvironment: TeamEnvironment;
  deleteCollection: Scalars['Boolean'];
  deleteRequest: Scalars['Boolean'];
  deleteTeam: Scalars['Boolean'];
  deleteTeamByAdmin: Scalars['Boolean'];
  deleteTeamEnvironment: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUserCollection: Scalars['Boolean'];
  deleteUserEnvironment: Scalars['Boolean'];
  deleteUserEnvironments: Scalars['Int'];
  deleteUserRequest: Scalars['Boolean'];
  importCollectionsFromJSON: Scalars['Boolean'];
  importUserCollectionsFromJSON: Scalars['Boolean'];
  inviteNewUser: InvitedUser;
  leaveTeam: Scalars['Boolean'];
  makeUserAdmin: Scalars['Boolean'];
  moveCollection: TeamCollection;
  moveRequest: TeamRequest;
  moveUserCollection: UserCollection;
  moveUserRequest: UserRequest;
  removeRequestFromHistory: UserHistory;
  removeTeamMember: Scalars['Boolean'];
  removeUserAsAdmin: Scalars['Boolean'];
  removeUserByAdmin: Scalars['Boolean'];
  removeUserFromTeamByAdmin: Scalars['Boolean'];
  renameCollection: TeamCollection;
  renameTeam: Team;
  renameTeamByAdmin: Team;
  renameUserCollection: UserCollection;
  replaceCollectionsWithJSON: Scalars['Boolean'];
  revokeShortcode: Scalars['Boolean'];
  revokeTeamInvitation: Scalars['Boolean'];
  toggleHistoryStarStatus: UserHistory;
  updateCollectionOrder: Scalars['Boolean'];
  updateGQLUserRequest: UserRequest;
  updateLookUpRequestOrder: Scalars['Boolean'];
  updateRESTUserRequest: UserRequest;
  updateRequest: TeamRequest;
  updateTeamEnvironment: TeamEnvironment;
  updateTeamMemberRole: TeamMember;
  updateUserCollectionOrder: Scalars['Boolean'];
  updateUserEnvironment: UserEnvironment;
  updateUserSessions: User;
  updateUserSettings: UserSettings;
};


export type MutationAcceptTeamInvitationArgs = {
  inviteID: Scalars['ID'];
};


export type MutationAddUserToTeamByAdminArgs = {
  role: TeamMemberRole;
  teamID: Scalars['ID'];
  userEmail: Scalars['String'];
};


export type MutationChangeUserRoleInTeamByAdminArgs = {
  newRole: TeamMemberRole;
  teamID: Scalars['ID'];
  userUID: Scalars['ID'];
};


export type MutationClearGlobalEnvironmentsArgs = {
  id: Scalars['ID'];
};


export type MutationCreateChildCollectionArgs = {
  childTitle: Scalars['String'];
  collectionID: Scalars['ID'];
};


export type MutationCreateDuplicateEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGqlChildUserCollectionArgs = {
  parentUserCollectionID: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateGqlRootUserCollectionArgs = {
  title: Scalars['String'];
};


export type MutationCreateGqlUserRequestArgs = {
  collectionID: Scalars['ID'];
  request: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateRestChildUserCollectionArgs = {
  parentUserCollectionID: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateRestRootUserCollectionArgs = {
  title: Scalars['String'];
};


export type MutationCreateRestUserRequestArgs = {
  collectionID: Scalars['ID'];
  request: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateRequestInCollectionArgs = {
  collectionID: Scalars['ID'];
  data: CreateTeamRequestInput;
};


export type MutationCreateRootCollectionArgs = {
  teamID: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateShortcodeArgs = {
  request: Scalars['String'];
};


export type MutationCreateTeamArgs = {
  name: Scalars['String'];
};


export type MutationCreateTeamByAdminArgs = {
  name: Scalars['String'];
  userUid: Scalars['ID'];
};


export type MutationCreateTeamEnvironmentArgs = {
  name: Scalars['String'];
  teamID: Scalars['ID'];
  variables: Scalars['String'];
};


export type MutationCreateTeamInvitationArgs = {
  inviteeEmail: Scalars['String'];
  inviteeRole: TeamMemberRole;
  teamID: Scalars['ID'];
};


export type MutationCreateUserEnvironmentArgs = {
  name: Scalars['String'];
  variables: Scalars['String'];
};


export type MutationCreateUserGlobalEnvironmentArgs = {
  variables: Scalars['String'];
};


export type MutationCreateUserHistoryArgs = {
  reqData: Scalars['String'];
  reqType: ReqType;
  resMetadata: Scalars['String'];
};


export type MutationCreateUserSettingsArgs = {
  properties: Scalars['String'];
};


export type MutationDeleteAllUserHistoryArgs = {
  reqType: ReqType;
};


export type MutationDeleteAllVariablesFromTeamEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCollectionArgs = {
  collectionID: Scalars['ID'];
};


export type MutationDeleteRequestArgs = {
  requestID: Scalars['ID'];
};


export type MutationDeleteTeamArgs = {
  teamID: Scalars['ID'];
};


export type MutationDeleteTeamByAdminArgs = {
  teamID: Scalars['ID'];
};


export type MutationDeleteTeamEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserCollectionArgs = {
  userCollectionID: Scalars['ID'];
};


export type MutationDeleteUserEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserRequestArgs = {
  id: Scalars['ID'];
};


export type MutationImportCollectionsFromJsonArgs = {
  jsonString: Scalars['String'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
  teamID: Scalars['ID'];
};


export type MutationImportUserCollectionsFromJsonArgs = {
  jsonString: Scalars['String'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
  reqType: ReqType;
};


export type MutationInviteNewUserArgs = {
  inviteeEmail: Scalars['String'];
};


export type MutationLeaveTeamArgs = {
  teamID: Scalars['ID'];
};


export type MutationMakeUserAdminArgs = {
  userUID: Scalars['ID'];
};


export type MutationMoveCollectionArgs = {
  collectionID: Scalars['ID'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
};


export type MutationMoveRequestArgs = {
  destCollID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  requestID: Scalars['ID'];
  srcCollID?: InputMaybe<Scalars['ID']>;
};


export type MutationMoveUserCollectionArgs = {
  destCollectionID?: InputMaybe<Scalars['ID']>;
  userCollectionID: Scalars['ID'];
};


export type MutationMoveUserRequestArgs = {
  destinationCollectionID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  requestID: Scalars['ID'];
  sourceCollectionID: Scalars['ID'];
};


export type MutationRemoveRequestFromHistoryArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTeamMemberArgs = {
  teamID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationRemoveUserAsAdminArgs = {
  userUID: Scalars['ID'];
};


export type MutationRemoveUserByAdminArgs = {
  userUID: Scalars['ID'];
};


export type MutationRemoveUserFromTeamByAdminArgs = {
  teamID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationRenameCollectionArgs = {
  collectionID: Scalars['ID'];
  newTitle: Scalars['String'];
};


export type MutationRenameTeamArgs = {
  newName: Scalars['String'];
  teamID: Scalars['ID'];
};


export type MutationRenameTeamByAdminArgs = {
  newName: Scalars['String'];
  teamID: Scalars['ID'];
};


export type MutationRenameUserCollectionArgs = {
  newTitle: Scalars['String'];
  userCollectionID: Scalars['ID'];
};


export type MutationReplaceCollectionsWithJsonArgs = {
  jsonString: Scalars['String'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
  teamID: Scalars['ID'];
};


export type MutationRevokeShortcodeArgs = {
  code: Scalars['ID'];
};


export type MutationRevokeTeamInvitationArgs = {
  inviteID: Scalars['ID'];
};


export type MutationToggleHistoryStarStatusArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCollectionOrderArgs = {
  collectionID: Scalars['ID'];
  destCollID?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateGqlUserRequestArgs = {
  id: Scalars['ID'];
  request?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateLookUpRequestOrderArgs = {
  collectionID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  requestID: Scalars['ID'];
};


export type MutationUpdateRestUserRequestArgs = {
  id: Scalars['ID'];
  request?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateRequestArgs = {
  data: UpdateTeamRequestInput;
  requestID: Scalars['ID'];
};


export type MutationUpdateTeamEnvironmentArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  variables: Scalars['String'];
};


export type MutationUpdateTeamMemberRoleArgs = {
  newRole: TeamMemberRole;
  teamID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationUpdateUserCollectionOrderArgs = {
  collectionID: Scalars['ID'];
  nextCollectionID?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateUserEnvironmentArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  variables: Scalars['String'];
};


export type MutationUpdateUserSessionsArgs = {
  currentSession: Scalars['String'];
  sessionType: SessionType;
};


export type MutationUpdateUserSettingsArgs = {
  properties: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  admin: Admin;
  collection?: Maybe<TeamCollection>;
  exportCollectionsToJSON: Scalars['String'];
  exportUserCollectionsToJSON: UserCollectionExportJsonData;
  me: User;
  myShortcodes: Array<Shortcode>;
  myTeams: Array<Team>;
  request?: Maybe<TeamRequest>;
  requestsInCollection: Array<TeamRequest>;
  rootCollectionsOfTeam: Array<TeamCollection>;
  rootGQLUserCollections: Array<UserCollection>;
  rootRESTUserCollections: Array<UserCollection>;
  searchForRequest: Array<TeamRequest>;
  shortcode?: Maybe<Shortcode>;
  team?: Maybe<Team>;
  teamInvitation: TeamInvitation;
  userCollection: UserCollection;
  userGQLRequests: Array<UserRequest>;
  userRESTRequests: Array<UserRequest>;
  userRequest: UserRequest;
};


export type QueryCollectionArgs = {
  collectionID: Scalars['ID'];
};


export type QueryExportCollectionsToJsonArgs = {
  teamID: Scalars['ID'];
};


export type QueryExportUserCollectionsToJsonArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  collectionType: ReqType;
};


export type QueryMyShortcodesArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryMyTeamsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
};


export type QueryRequestArgs = {
  requestID: Scalars['ID'];
};


export type QueryRequestsInCollectionArgs = {
  collectionID: Scalars['ID'];
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryRootCollectionsOfTeamArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  teamID: Scalars['ID'];
};


export type QueryRootGqlUserCollectionsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryRootRestUserCollectionsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchForRequestArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  searchTerm: Scalars['String'];
  take?: InputMaybe<Scalars['Int']>;
  teamID: Scalars['ID'];
};


export type QueryShortcodeArgs = {
  code: Scalars['ID'];
};


export type QueryTeamArgs = {
  teamID: Scalars['ID'];
};


export type QueryTeamInvitationArgs = {
  inviteID: Scalars['ID'];
};


export type QueryUserCollectionArgs = {
  userCollectionID: Scalars['ID'];
};


export type QueryUserGqlRequestsArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUserRestRequestsArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUserRequestArgs = {
  id: Scalars['ID'];
};

export enum ReqType {
  Gql = 'GQL',
  Rest = 'REST'
}

export type RequestReorderData = {
  __typename?: 'RequestReorderData';
  nextRequest?: Maybe<TeamRequest>;
  request: TeamRequest;
};

export enum SessionType {
  Gql = 'GQL',
  Rest = 'REST'
}

export type Shortcode = {
  __typename?: 'Shortcode';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  request: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  collectionOrderUpdated: CollectionReorderData;
  myShortcodesCreated: Shortcode;
  myShortcodesRevoked: Shortcode;
  requestMoved: TeamRequest;
  requestOrderUpdated: RequestReorderData;
  teamCollectionAdded: TeamCollection;
  teamCollectionMoved: TeamCollection;
  teamCollectionRemoved: Scalars['ID'];
  teamCollectionUpdated: TeamCollection;
  teamEnvironmentCreated: TeamEnvironment;
  teamEnvironmentDeleted: TeamEnvironment;
  teamEnvironmentUpdated: TeamEnvironment;
  teamInvitationAdded: TeamInvitation;
  teamInvitationRemoved: Scalars['ID'];
  teamMemberAdded: TeamMember;
  teamMemberRemoved: Scalars['ID'];
  teamMemberUpdated: TeamMember;
  teamRequestAdded: TeamRequest;
  teamRequestDeleted: Scalars['ID'];
  teamRequestUpdated: TeamRequest;
  userCollectionCreated: UserCollection;
  userCollectionMoved: UserCollection;
  userCollectionOrderUpdated: UserCollectionReorderData;
  userCollectionRemoved: UserCollectionRemovedData;
  userCollectionUpdated: UserCollection;
  userDeleted: User;
  userEnvironmentCreated: UserEnvironment;
  userEnvironmentDeleteMany: Scalars['Int'];
  userEnvironmentDeleted: UserEnvironment;
  userEnvironmentUpdated: UserEnvironment;
  userHistoryCreated: UserHistory;
  userHistoryDeleted: UserHistory;
  userHistoryDeletedMany: UserHistoryDeletedManyData;
  userHistoryUpdated: UserHistory;
  userInvited: InvitedUser;
  userRequestCreated: UserRequest;
  userRequestDeleted: UserRequest;
  userRequestMoved: UserRequestReorderData;
  userRequestUpdated: UserRequest;
  userSettingsCreated: UserSettings;
  userSettingsUpdated: UserSettings;
  userUpdated: User;
};


export type SubscriptionCollectionOrderUpdatedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionRequestMovedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionRequestOrderUpdatedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamCollectionAddedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamCollectionMovedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamCollectionRemovedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamCollectionUpdatedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamEnvironmentCreatedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamEnvironmentDeletedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamEnvironmentUpdatedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamInvitationAddedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamInvitationRemovedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamMemberAddedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamMemberRemovedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamMemberUpdatedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamRequestAddedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamRequestDeletedArgs = {
  teamID: Scalars['ID'];
};


export type SubscriptionTeamRequestUpdatedArgs = {
  teamID: Scalars['ID'];
};

export type Team = {
  __typename?: 'Team';
  editorsCount: Scalars['Int'];
  id: Scalars['ID'];
  members: Array<TeamMember>;
  myRole?: Maybe<TeamMemberRole>;
  name: Scalars['String'];
  ownersCount: Scalars['Int'];
  teamEnvironments: Array<TeamEnvironment>;
  teamInvitations: Array<TeamInvitation>;
  teamMembers: Array<TeamMember>;
  viewersCount: Scalars['Int'];
};


export type TeamMembersArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
};

export type TeamCollection = {
  __typename?: 'TeamCollection';
  children: Array<TeamCollection>;
  id: Scalars['ID'];
  parent?: Maybe<TeamCollection>;
  parentID?: Maybe<Scalars['ID']>;
  team: Team;
  title: Scalars['String'];
};


export type TeamCollectionChildrenArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type TeamEnvironment = {
  __typename?: 'TeamEnvironment';
  id: Scalars['ID'];
  name: Scalars['String'];
  teamID: Scalars['ID'];
  variables: Scalars['String'];
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  creator: User;
  creatorUid: Scalars['ID'];
  id: Scalars['ID'];
  inviteeEmail: Scalars['String'];
  inviteeRole: TeamMemberRole;
  team: Team;
  teamID: Scalars['ID'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  membershipID: Scalars['ID'];
  role: TeamMemberRole;
  user: User;
};

export enum TeamMemberRole {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type TeamRequest = {
  __typename?: 'TeamRequest';
  collection: TeamCollection;
  collectionID: Scalars['ID'];
  id: Scalars['ID'];
  request: Scalars['String'];
  team: Team;
  teamID: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateTeamRequestInput = {
  request?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  GQLHistory: Array<UserHistory>;
  RESTHistory: Array<UserHistory>;
  createdOn: Scalars['DateTime'];
  currentGQLSession?: Maybe<Scalars['String']>;
  currentRESTSession?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  environments: Array<UserEnvironment>;
  globalEnvironments: UserEnvironment;
  isAdmin: Scalars['Boolean'];
  photoURL?: Maybe<Scalars['String']>;
  settings: UserSettings;
  uid: Scalars['ID'];
};


export type UserGqlHistoryArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserRestHistoryArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UserCollection = {
  __typename?: 'UserCollection';
  childrenGQL: Array<UserCollection>;
  childrenREST: Array<UserCollection>;
  id: Scalars['ID'];
  parent?: Maybe<UserCollection>;
  requests: Array<UserRequest>;
  title: Scalars['String'];
  type: ReqType;
  user: User;
};


export type UserCollectionChildrenGqlArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserCollectionChildrenRestArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserCollectionRequestsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UserCollectionExportJsonData = {
  __typename?: 'UserCollectionExportJSONData';
  collectionType: ReqType;
  exportedCollection: Scalars['ID'];
};

export type UserCollectionRemovedData = {
  __typename?: 'UserCollectionRemovedData';
  id: Scalars['ID'];
  type: ReqType;
};

export type UserCollectionReorderData = {
  __typename?: 'UserCollectionReorderData';
  nextUserCollection?: Maybe<UserCollection>;
  userCollection: UserCollection;
};

export type UserEnvironment = {
  __typename?: 'UserEnvironment';
  id: Scalars['ID'];
  isGlobal: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  userUid: Scalars['ID'];
  variables: Scalars['String'];
};

export type UserHistory = {
  __typename?: 'UserHistory';
  executedOn: Scalars['DateTime'];
  id: Scalars['ID'];
  isStarred: Scalars['Boolean'];
  reqType: ReqType;
  request: Scalars['String'];
  responseMetadata: Scalars['String'];
  userUid: Scalars['ID'];
};

export type UserHistoryDeletedManyData = {
  __typename?: 'UserHistoryDeletedManyData';
  count: Scalars['Int'];
  reqType: ReqType;
};

export type UserRequest = {
  __typename?: 'UserRequest';
  collectionID: Scalars['ID'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  request: Scalars['String'];
  title: Scalars['String'];
  type: ReqType;
  user: User;
};

export type UserRequestReorderData = {
  __typename?: 'UserRequestReorderData';
  nextRequest?: Maybe<UserRequest>;
  request: UserRequest;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  id: Scalars['ID'];
  properties: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  userUid: Scalars['ID'];
};

export type AcceptTeamInvitationMutationVariables = Exact<{
  inviteID: Scalars['ID'];
}>;


export type AcceptTeamInvitationMutation = { __typename?: 'Mutation', acceptTeamInvitation: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, displayName?: string | null, photoURL?: string | null, email?: string | null } } };

export type CreateChildCollectionMutationVariables = Exact<{
  childTitle: Scalars['String'];
  collectionID: Scalars['ID'];
}>;


export type CreateChildCollectionMutation = { __typename?: 'Mutation', createChildCollection: { __typename?: 'TeamCollection', id: string } };

export type CreateDuplicateEnvironmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreateDuplicateEnvironmentMutation = { __typename?: 'Mutation', createDuplicateEnvironment: { __typename?: 'TeamEnvironment', id: string, teamID: string, name: string, variables: string } };

export type CreateNewRootCollectionMutationVariables = Exact<{
  title: Scalars['String'];
  teamID: Scalars['ID'];
}>;


export type CreateNewRootCollectionMutation = { __typename?: 'Mutation', createRootCollection: { __typename?: 'TeamCollection', id: string } };

export type CreateRequestInCollectionMutationVariables = Exact<{
  data: CreateTeamRequestInput;
  collectionID: Scalars['ID'];
}>;


export type CreateRequestInCollectionMutation = { __typename?: 'Mutation', createRequestInCollection: { __typename?: 'TeamRequest', id: string, collection: { __typename?: 'TeamCollection', id: string, team: { __typename?: 'Team', id: string, name: string } } } };

export type CreateShortcodeMutationVariables = Exact<{
  request: Scalars['String'];
}>;


export type CreateShortcodeMutation = { __typename?: 'Mutation', createShortcode: { __typename?: 'Shortcode', id: string, request: string } };

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', id: string, name: string, myRole?: TeamMemberRole | null, ownersCount: number, editorsCount: number, viewersCount: number, members: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, displayName?: string | null, email?: string | null, photoURL?: string | null } }> } };

export type CreateTeamEnvironmentMutationVariables = Exact<{
  variables: Scalars['String'];
  teamID: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateTeamEnvironmentMutation = { __typename?: 'Mutation', createTeamEnvironment: { __typename?: 'TeamEnvironment', variables: string, name: string, teamID: string } };

export type CreateTeamInvitationMutationVariables = Exact<{
  inviteeEmail: Scalars['String'];
  inviteeRole: TeamMemberRole;
  teamID: Scalars['ID'];
}>;


export type CreateTeamInvitationMutation = { __typename?: 'Mutation', createTeamInvitation: { __typename?: 'TeamInvitation', id: string, teamID: string, creatorUid: string, inviteeEmail: string, inviteeRole: TeamMemberRole } };

export type DeleteCollectionMutationVariables = Exact<{
  collectionID: Scalars['ID'];
}>;


export type DeleteCollectionMutation = { __typename?: 'Mutation', deleteCollection: boolean };

export type DeleteRequestMutationVariables = Exact<{
  requestID: Scalars['ID'];
}>;


export type DeleteRequestMutation = { __typename?: 'Mutation', deleteRequest: boolean };

export type DeleteShortcodeMutationVariables = Exact<{
  code: Scalars['ID'];
}>;


export type DeleteShortcodeMutation = { __typename?: 'Mutation', revokeShortcode: boolean };

export type DeleteTeamMutationVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type DeleteTeamMutation = { __typename?: 'Mutation', deleteTeam: boolean };

export type DeleteTeamEnvironmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTeamEnvironmentMutation = { __typename?: 'Mutation', deleteTeamEnvironment: boolean };

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type ImportFromJsonMutationVariables = Exact<{
  jsonString: Scalars['String'];
  teamID: Scalars['ID'];
}>;


export type ImportFromJsonMutation = { __typename?: 'Mutation', importCollectionsFromJSON: boolean };

export type LeaveTeamMutationVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type LeaveTeamMutation = { __typename?: 'Mutation', leaveTeam: boolean };

export type MoveRestTeamCollectionMutationVariables = Exact<{
  collectionID: Scalars['ID'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
}>;


export type MoveRestTeamCollectionMutation = { __typename?: 'Mutation', moveCollection: { __typename?: 'TeamCollection', id: string } };

export type MoveRestTeamRequestMutationVariables = Exact<{
  collectionID: Scalars['ID'];
  requestID: Scalars['ID'];
}>;


export type MoveRestTeamRequestMutation = { __typename?: 'Mutation', moveRequest: { __typename?: 'TeamRequest', id: string } };

export type RemoveTeamMemberMutationVariables = Exact<{
  userUid: Scalars['ID'];
  teamID: Scalars['ID'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember: boolean };

export type RenameCollectionMutationVariables = Exact<{
  newTitle: Scalars['String'];
  collectionID: Scalars['ID'];
}>;


export type RenameCollectionMutation = { __typename?: 'Mutation', renameCollection: { __typename?: 'TeamCollection', id: string } };

export type RenameTeamMutationVariables = Exact<{
  newName: Scalars['String'];
  teamID: Scalars['ID'];
}>;


export type RenameTeamMutation = { __typename?: 'Mutation', renameTeam: { __typename?: 'Team', id: string, name: string, teamMembers: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string } }> } };

export type RevokeTeamInvitationMutationVariables = Exact<{
  inviteID: Scalars['ID'];
}>;


export type RevokeTeamInvitationMutation = { __typename?: 'Mutation', revokeTeamInvitation: boolean };

export type UpdateCollectionOrderMutationVariables = Exact<{
  collectionID: Scalars['ID'];
  destCollID?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateCollectionOrderMutation = { __typename?: 'Mutation', updateCollectionOrder: boolean };

export type UpdateLookUpRequestOrderMutationVariables = Exact<{
  requestID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  collectionID: Scalars['ID'];
}>;


export type UpdateLookUpRequestOrderMutation = { __typename?: 'Mutation', updateLookUpRequestOrder: boolean };

export type UpdateRequestMutationVariables = Exact<{
  data: UpdateTeamRequestInput;
  requestID: Scalars['ID'];
}>;


export type UpdateRequestMutation = { __typename?: 'Mutation', updateRequest: { __typename?: 'TeamRequest', id: string, title: string } };

export type UpdateTeamEnvironmentMutationVariables = Exact<{
  variables: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateTeamEnvironmentMutation = { __typename?: 'Mutation', updateTeamEnvironment: { __typename?: 'TeamEnvironment', variables: string, name: string, id: string } };

export type UpdateTeamMemberRoleMutationVariables = Exact<{
  newRole: TeamMemberRole;
  userUid: Scalars['ID'];
  teamID: Scalars['ID'];
}>;


export type UpdateTeamMemberRoleMutation = { __typename?: 'Mutation', updateTeamMemberRole: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole } };

export type ExportAsJsonQueryVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type ExportAsJsonQuery = { __typename?: 'Query', exportCollectionsToJSON: string };

export type GetCollectionChildrenQueryVariables = Exact<{
  collectionID: Scalars['ID'];
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type GetCollectionChildrenQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', children: Array<{ __typename?: 'TeamCollection', id: string, title: string }> } | null };

export type GetCollectionChildrenIDsQueryVariables = Exact<{
  collectionID: Scalars['ID'];
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type GetCollectionChildrenIDsQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', children: Array<{ __typename?: 'TeamCollection', id: string }> } | null };

export type GetCollectionRequestsQueryVariables = Exact<{
  collectionID: Scalars['ID'];
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type GetCollectionRequestsQuery = { __typename?: 'Query', requestsInCollection: Array<{ __typename?: 'TeamRequest', id: string, title: string, request: string }> };

export type GetCollectionTitleQueryVariables = Exact<{
  collectionID: Scalars['ID'];
}>;


export type GetCollectionTitleQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', title: string } | null };

export type GetInviteDetailsQueryVariables = Exact<{
  inviteID: Scalars['ID'];
}>;


export type GetInviteDetailsQuery = { __typename?: 'Query', teamInvitation: { __typename?: 'TeamInvitation', id: string, inviteeEmail: string, inviteeRole: TeamMemberRole, team: { __typename?: 'Team', id: string, name: string }, creator: { __typename?: 'User', uid: string, displayName?: string | null, email?: string | null } } };

export type GetUserShortcodesQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type GetUserShortcodesQuery = { __typename?: 'Query', myShortcodes: Array<{ __typename?: 'Shortcode', id: string, request: string, createdOn: any }> };

export type GetMyTeamsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type GetMyTeamsQuery = { __typename?: 'Query', myTeams: Array<{ __typename?: 'Team', id: string, name: string, myRole?: TeamMemberRole | null, ownersCount: number, teamMembers: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', photoURL?: string | null, displayName?: string | null, email?: string | null, uid: string } }> }> };

export type GetSingleCollectionQueryVariables = Exact<{
  collectionID: Scalars['ID'];
}>;


export type GetSingleCollectionQuery = { __typename?: 'Query', collection?: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } | null };

export type GetSingleRequestQueryVariables = Exact<{
  requestID: Scalars['ID'];
}>;


export type GetSingleRequestQuery = { __typename?: 'Query', request?: { __typename?: 'TeamRequest', id: string, collectionID: string, title: string, request: string } | null };

export type GetTeamQueryVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type GetTeamQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, name: string, teamMembers: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } }> } | null };

export type GetTeamEnvironmentsQueryVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type GetTeamEnvironmentsQuery = { __typename?: 'Query', team?: { __typename?: 'Team', teamEnvironments: Array<{ __typename?: 'TeamEnvironment', id: string, name: string, variables: string, teamID: string }> } | null };

export type GetTeamMembersQueryVariables = Exact<{
  teamID: Scalars['ID'];
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type GetTeamMembersQuery = { __typename?: 'Query', team?: { __typename?: 'Team', members: Array<{ __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } }> } | null };

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = { __typename?: 'Query', me: { __typename?: 'User', uid: string, displayName?: string | null, email?: string | null, photoURL?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', uid: string, displayName?: string | null, photoURL?: string | null } };

export type ResolveShortcodeQueryVariables = Exact<{
  code: Scalars['ID'];
}>;


export type ResolveShortcodeQuery = { __typename?: 'Query', shortcode?: { __typename?: 'Shortcode', id: string, request: string } | null };

export type RootCollectionsOfTeamQueryVariables = Exact<{
  teamID: Scalars['ID'];
  cursor?: InputMaybe<Scalars['ID']>;
}>;


export type RootCollectionsOfTeamQuery = { __typename?: 'Query', rootCollectionsOfTeam: Array<{ __typename?: 'TeamCollection', id: string, title: string }> };

export type GetPendingInvitesQueryVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type GetPendingInvitesQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, teamInvitations: Array<{ __typename?: 'TeamInvitation', inviteeRole: TeamMemberRole, inviteeEmail: string, id: string }> } | null };

export type ShortcodeCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ShortcodeCreatedSubscription = { __typename?: 'Subscription', myShortcodesCreated: { __typename?: 'Shortcode', id: string, request: string, createdOn: any } };

export type ShortcodeDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ShortcodeDeletedSubscription = { __typename?: 'Subscription', myShortcodesRevoked: { __typename?: 'Shortcode', id: string } };

export type TeamCollectionAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamCollectionAddedSubscription = { __typename?: 'Subscription', teamCollectionAdded: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } };

export type TeamCollectionMovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamCollectionMovedSubscription = { __typename?: 'Subscription', teamCollectionMoved: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } };

export type TeamCollectionOrderUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamCollectionOrderUpdatedSubscription = { __typename?: 'Subscription', collectionOrderUpdated: { __typename?: 'CollectionReorderData', collection: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null }, nextCollection?: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } | null } };

export type TeamCollectionRemovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamCollectionRemovedSubscription = { __typename?: 'Subscription', teamCollectionRemoved: string };

export type TeamCollectionUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamCollectionUpdatedSubscription = { __typename?: 'Subscription', teamCollectionUpdated: { __typename?: 'TeamCollection', id: string, title: string, parent?: { __typename?: 'TeamCollection', id: string } | null } };

export type TeamEnvironmentCreatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamEnvironmentCreatedSubscription = { __typename?: 'Subscription', teamEnvironmentCreated: { __typename?: 'TeamEnvironment', id: string, teamID: string, name: string, variables: string } };

export type TeamEnvironmentDeletedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamEnvironmentDeletedSubscription = { __typename?: 'Subscription', teamEnvironmentDeleted: { __typename?: 'TeamEnvironment', id: string } };

export type TeamEnvironmentUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamEnvironmentUpdatedSubscription = { __typename?: 'Subscription', teamEnvironmentUpdated: { __typename?: 'TeamEnvironment', id: string, teamID: string, name: string, variables: string } };

export type TeamInvitationAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamInvitationAddedSubscription = { __typename?: 'Subscription', teamInvitationAdded: { __typename?: 'TeamInvitation', id: string } };

export type TeamInvitationRemovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamInvitationRemovedSubscription = { __typename?: 'Subscription', teamInvitationRemoved: string };

export type TeamMemberAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamMemberAddedSubscription = { __typename?: 'Subscription', teamMemberAdded: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } } };

export type TeamMemberRemovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamMemberRemovedSubscription = { __typename?: 'Subscription', teamMemberRemoved: string };

export type TeamMemberUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamMemberUpdatedSubscription = { __typename?: 'Subscription', teamMemberUpdated: { __typename?: 'TeamMember', membershipID: string, role: TeamMemberRole, user: { __typename?: 'User', uid: string, email?: string | null } } };

export type TeamRequestAddedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamRequestAddedSubscription = { __typename?: 'Subscription', teamRequestAdded: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } };

export type TeamRequestDeletedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamRequestDeletedSubscription = { __typename?: 'Subscription', teamRequestDeleted: string };

export type TeamRequestMovedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamRequestMovedSubscription = { __typename?: 'Subscription', requestMoved: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } };

export type TeamRequestOrderUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamRequestOrderUpdatedSubscription = { __typename?: 'Subscription', requestOrderUpdated: { __typename?: 'RequestReorderData', request: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string }, nextRequest?: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } | null } };

export type TeamRequestUpdatedSubscriptionVariables = Exact<{
  teamID: Scalars['ID'];
}>;


export type TeamRequestUpdatedSubscription = { __typename?: 'Subscription', teamRequestUpdated: { __typename?: 'TeamRequest', id: string, collectionID: string, request: string, title: string } };


export const AcceptTeamInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptTeamInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptTeamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<AcceptTeamInvitationMutation, AcceptTeamInvitationMutationVariables>;
export const CreateChildCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateChildCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"childTitle"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createChildCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"childTitle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"childTitle"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateChildCollectionMutation, CreateChildCollectionMutationVariables>;
export const CreateDuplicateEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateDuplicateEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDuplicateEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<CreateDuplicateEnvironmentMutation, CreateDuplicateEnvironmentMutationVariables>;
export const CreateNewRootCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNewRootCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRootCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateNewRootCollectionMutation, CreateNewRootCollectionMutationVariables>;
export const CreateRequestInCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateRequestInCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTeamRequestInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRequestInCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"team"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateRequestInCollectionMutation, CreateRequestInCollectionMutationVariables>;
export const CreateShortcodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateShortcode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createShortcode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<CreateShortcodeMutation, CreateShortcodeMutationVariables>;
export const CreateTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"members"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"myRole"}},{"kind":"Field","name":{"kind":"Name","value":"ownersCount"}},{"kind":"Field","name":{"kind":"Name","value":"editorsCount"}},{"kind":"Field","name":{"kind":"Name","value":"viewersCount"}}]}}]}}]} as unknown as DocumentNode<CreateTeamMutation, CreateTeamMutationVariables>;
export const CreateTeamEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeamEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeamEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}}]}}]}}]} as unknown as DocumentNode<CreateTeamEnvironmentMutation, CreateTeamEnvironmentMutationVariables>;
export const CreateTeamInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTeamInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteeEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteeRole"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TeamMemberRole"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteeRole"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteeRole"}}},{"kind":"Argument","name":{"kind":"Name","value":"inviteeEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteeEmail"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"creatorUid"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeEmail"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeRole"}}]}}]}}]} as unknown as DocumentNode<CreateTeamInvitationMutation, CreateTeamInvitationMutationVariables>;
export const DeleteCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}]}]}}]} as unknown as DocumentNode<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const DeleteRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}]}]}}]} as unknown as DocumentNode<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const DeleteShortcodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteShortcode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revokeShortcode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}]}]}}]} as unknown as DocumentNode<DeleteShortcodeMutation, DeleteShortcodeMutationVariables>;
export const DeleteTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const DeleteTeamEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteTeamEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteTeamEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteTeamEnvironmentMutation, DeleteTeamEnvironmentMutationVariables>;
export const DeleteUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUser"}}]}}]} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const ImportFromJsonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"importFromJSON"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jsonString"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"importCollectionsFromJSON"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jsonString"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jsonString"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<ImportFromJsonMutation, ImportFromJsonMutationVariables>;
export const LeaveTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LeaveTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leaveTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<LeaveTeamMutation, LeaveTeamMutationVariables>;
export const MoveRestTeamCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveRESTTeamCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentCollectionID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"parentCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentCollectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MoveRestTeamCollectionMutation, MoveRestTeamCollectionMutationVariables>;
export const MoveRestTeamRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveRESTTeamRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"destCollID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MoveRestTeamRequestMutation, MoveRestTeamRequestMutationVariables>;
export const RemoveTeamMemberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveTeamMember"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeTeamMember"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const RenameCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RenameCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renameCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newTitle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<RenameCollectionMutation, RenameCollectionMutationVariables>;
export const RenameTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RenameTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renameTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newName"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamMembers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<RenameTeamMutation, RenameTeamMutationVariables>;
export const RevokeTeamInvitationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RevokeTeamInvitation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revokeTeamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}}}]}]}}]} as unknown as DocumentNode<RevokeTeamInvitationMutation, RevokeTeamInvitationMutationVariables>;
export const UpdateCollectionOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCollectionOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"destCollID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCollectionOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"destCollID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"destCollID"}}}]}]}}]} as unknown as DocumentNode<UpdateCollectionOrderMutation, UpdateCollectionOrderMutationVariables>;
export const UpdateLookUpRequestOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateLookUpRequestOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextRequestID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateLookUpRequestOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextRequestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextRequestID"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}]}]}}]} as unknown as DocumentNode<UpdateLookUpRequestOrderMutation, UpdateLookUpRequestOrderMutationVariables>;
export const UpdateRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateTeamRequestInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<UpdateRequestMutation, UpdateRequestMutationVariables>;
export const UpdateTeamEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTeamEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTeamEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}},{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateTeamEnvironmentMutation, UpdateTeamEnvironmentMutationVariables>;
export const UpdateTeamMemberRoleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTeamMemberRole"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newRole"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TeamMemberRole"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTeamMemberRole"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newRole"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newRole"}}},{"kind":"Argument","name":{"kind":"Name","value":"userUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userUid"}}},{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<UpdateTeamMemberRoleMutation, UpdateTeamMemberRoleMutationVariables>;
export const ExportAsJsonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExportAsJSON"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportCollectionsToJSON"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<ExportAsJsonQuery, ExportAsJsonQueryVariables>;
export const GetCollectionChildrenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionChildren"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"children"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}}]} as unknown as DocumentNode<GetCollectionChildrenQuery, GetCollectionChildrenQueryVariables>;
export const GetCollectionChildrenIDsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionChildrenIDs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"children"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetCollectionChildrenIDsQuery, GetCollectionChildrenIDsQueryVariables>;
export const GetCollectionRequestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionRequests"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestsInCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<GetCollectionRequestsQuery, GetCollectionRequestsQueryVariables>;
export const GetCollectionTitleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCollectionTitle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<GetCollectionTitleQuery, GetCollectionTitleQueryVariables>;
export const GetInviteDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInviteDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamInvitation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeEmail"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeRole"}},{"kind":"Field","name":{"kind":"Name","value":"team"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"creator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>;
export const GetUserShortcodesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserShortcodes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}}]}}]}}]} as unknown as DocumentNode<GetUserShortcodesQuery, GetUserShortcodesQueryVariables>;
export const GetMyTeamsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMyTeams"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myTeams"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"myRole"}},{"kind":"Field","name":{"kind":"Name","value":"ownersCount"}},{"kind":"Field","name":{"kind":"Name","value":"teamMembers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"photoURL"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetMyTeamsQuery, GetMyTeamsQueryVariables>;
export const GetSingleCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSingleCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetSingleCollectionQuery, GetSingleCollectionQueryVariables>;
export const GetSingleRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSingleRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<GetSingleRequestQuery, GetSingleRequestQueryVariables>;
export const GetTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"teamMembers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamEnvironmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTeamEnvironments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}}]}}]}}]}}]} as unknown as DocumentNode<GetTeamEnvironmentsQuery, GetTeamEnvironmentsQueryVariables>;
export const GetTeamMembersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTeamMembers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"members"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetTeamMembersQuery, GetTeamMembersQueryVariables>;
export const GetUserInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}}]}}]}}]} as unknown as DocumentNode<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const MeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"photoURL"}}]}}]}}]} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const ResolveShortcodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResolveShortcode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shortcode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<ResolveShortcodeQuery, ResolveShortcodeQueryVariables>;
export const RootCollectionsOfTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RootCollectionsOfTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rootCollectionsOfTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}},{"kind":"Argument","name":{"kind":"Name","value":"cursor"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cursor"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<RootCollectionsOfTeamQuery, RootCollectionsOfTeamQueryVariables>;
export const GetPendingInvitesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPendingInvites"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamInvitations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inviteeRole"}},{"kind":"Field","name":{"kind":"Name","value":"inviteeEmail"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<GetPendingInvitesQuery, GetPendingInvitesQueryVariables>;
export const ShortcodeCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ShortcodeCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodesCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"createdOn"}}]}}]}}]} as unknown as DocumentNode<ShortcodeCreatedSubscription, ShortcodeCreatedSubscriptionVariables>;
export const ShortcodeDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ShortcodeDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myShortcodesRevoked"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ShortcodeDeletedSubscription, ShortcodeDeletedSubscriptionVariables>;
export const TeamCollectionAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionAddedSubscription, TeamCollectionAddedSubscriptionVariables>;
export const TeamCollectionMovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionMoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionMoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionMovedSubscription, TeamCollectionMovedSubscriptionVariables>;
export const TeamCollectionOrderUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionOrderUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionOrderUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextCollection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionOrderUpdatedSubscription, TeamCollectionOrderUpdatedSubscriptionVariables>;
export const TeamCollectionRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionRemoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionRemoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamCollectionRemovedSubscription, TeamCollectionRemovedSubscriptionVariables>;
export const TeamCollectionUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamCollectionUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamCollectionUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<TeamCollectionUpdatedSubscription, TeamCollectionUpdatedSubscriptionVariables>;
export const TeamEnvironmentCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamEnvironmentCreated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironmentCreated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<TeamEnvironmentCreatedSubscription, TeamEnvironmentCreatedSubscriptionVariables>;
export const TeamEnvironmentDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamEnvironmentDeleted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironmentDeleted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<TeamEnvironmentDeletedSubscription, TeamEnvironmentDeletedSubscriptionVariables>;
export const TeamEnvironmentUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamEnvironmentUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamEnvironmentUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teamID"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<TeamEnvironmentUpdatedSubscription, TeamEnvironmentUpdatedSubscriptionVariables>;
export const TeamInvitationAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamInvitationAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamInvitationAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<TeamInvitationAddedSubscription, TeamInvitationAddedSubscriptionVariables>;
export const TeamInvitationRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamInvitationRemoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamInvitationRemoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamInvitationRemovedSubscription, TeamInvitationRemovedSubscriptionVariables>;
export const TeamMemberAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamMemberAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamMemberAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<TeamMemberAddedSubscription, TeamMemberAddedSubscriptionVariables>;
export const TeamMemberRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamMemberRemoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamMemberRemoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamMemberRemovedSubscription, TeamMemberRemovedSubscriptionVariables>;
export const TeamMemberUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamMemberUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamMemberUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipID"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<TeamMemberUpdatedSubscription, TeamMemberUpdatedSubscriptionVariables>;
export const TeamRequestAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamRequestAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<TeamRequestAddedSubscription, TeamRequestAddedSubscriptionVariables>;
export const TeamRequestDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestDeleted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamRequestDeleted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}]}]}}]} as unknown as DocumentNode<TeamRequestDeletedSubscription, TeamRequestDeletedSubscriptionVariables>;
export const TeamRequestMovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestMoved"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestMoved"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<TeamRequestMovedSubscription, TeamRequestMovedSubscriptionVariables>;
export const TeamRequestOrderUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestOrderUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestOrderUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}}]} as unknown as DocumentNode<TeamRequestOrderUpdatedSubscription, TeamRequestOrderUpdatedSubscriptionVariables>;
export const TeamRequestUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"TeamRequestUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"teamRequestUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<TeamRequestUpdatedSubscription, TeamRequestUpdatedSubscriptionVariables>;
export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  Admin?: (data: WithTypename<Admin>) => null | string,
  CollectionReorderData?: (data: WithTypename<CollectionReorderData>) => null | string,
  InvitedUser?: (data: WithTypename<InvitedUser>) => null | string,
  RequestReorderData?: (data: WithTypename<RequestReorderData>) => null | string,
  Shortcode?: (data: WithTypename<Shortcode>) => null | string,
  Team?: (data: WithTypename<Team>) => null | string,
  TeamCollection?: (data: WithTypename<TeamCollection>) => null | string,
  TeamEnvironment?: (data: WithTypename<TeamEnvironment>) => null | string,
  TeamInvitation?: (data: WithTypename<TeamInvitation>) => null | string,
  TeamMember?: (data: WithTypename<TeamMember>) => null | string,
  TeamRequest?: (data: WithTypename<TeamRequest>) => null | string,
  User?: (data: WithTypename<User>) => null | string,
  UserCollection?: (data: WithTypename<UserCollection>) => null | string,
  UserCollectionExportJSONData?: (data: WithTypename<UserCollectionExportJsonData>) => null | string,
  UserCollectionRemovedData?: (data: WithTypename<UserCollectionRemovedData>) => null | string,
  UserCollectionReorderData?: (data: WithTypename<UserCollectionReorderData>) => null | string,
  UserEnvironment?: (data: WithTypename<UserEnvironment>) => null | string,
  UserHistory?: (data: WithTypename<UserHistory>) => null | string,
  UserHistoryDeletedManyData?: (data: WithTypename<UserHistoryDeletedManyData>) => null | string,
  UserRequest?: (data: WithTypename<UserRequest>) => null | string,
  UserRequestReorderData?: (data: WithTypename<UserRequestReorderData>) => null | string,
  UserSettings?: (data: WithTypename<UserSettings>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    admin?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Admin> | string>,
    collection?: GraphCacheResolver<WithTypename<Query>, QueryCollectionArgs, WithTypename<TeamCollection> | string>,
    exportCollectionsToJSON?: GraphCacheResolver<WithTypename<Query>, QueryExportCollectionsToJsonArgs, Scalars['String'] | string>,
    exportUserCollectionsToJSON?: GraphCacheResolver<WithTypename<Query>, QueryExportUserCollectionsToJsonArgs, WithTypename<UserCollectionExportJsonData> | string>,
    me?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<User> | string>,
    myShortcodes?: GraphCacheResolver<WithTypename<Query>, QueryMyShortcodesArgs, Array<WithTypename<Shortcode> | string>>,
    myTeams?: GraphCacheResolver<WithTypename<Query>, QueryMyTeamsArgs, Array<WithTypename<Team> | string>>,
    request?: GraphCacheResolver<WithTypename<Query>, QueryRequestArgs, WithTypename<TeamRequest> | string>,
    requestsInCollection?: GraphCacheResolver<WithTypename<Query>, QueryRequestsInCollectionArgs, Array<WithTypename<TeamRequest> | string>>,
    rootCollectionsOfTeam?: GraphCacheResolver<WithTypename<Query>, QueryRootCollectionsOfTeamArgs, Array<WithTypename<TeamCollection> | string>>,
    rootGQLUserCollections?: GraphCacheResolver<WithTypename<Query>, QueryRootGqlUserCollectionsArgs, Array<WithTypename<UserCollection> | string>>,
    rootRESTUserCollections?: GraphCacheResolver<WithTypename<Query>, QueryRootRestUserCollectionsArgs, Array<WithTypename<UserCollection> | string>>,
    searchForRequest?: GraphCacheResolver<WithTypename<Query>, QuerySearchForRequestArgs, Array<WithTypename<TeamRequest> | string>>,
    shortcode?: GraphCacheResolver<WithTypename<Query>, QueryShortcodeArgs, WithTypename<Shortcode> | string>,
    team?: GraphCacheResolver<WithTypename<Query>, QueryTeamArgs, WithTypename<Team> | string>,
    teamInvitation?: GraphCacheResolver<WithTypename<Query>, QueryTeamInvitationArgs, WithTypename<TeamInvitation> | string>,
    userCollection?: GraphCacheResolver<WithTypename<Query>, QueryUserCollectionArgs, WithTypename<UserCollection> | string>,
    userGQLRequests?: GraphCacheResolver<WithTypename<Query>, QueryUserGqlRequestsArgs, Array<WithTypename<UserRequest> | string>>,
    userRESTRequests?: GraphCacheResolver<WithTypename<Query>, QueryUserRestRequestsArgs, Array<WithTypename<UserRequest> | string>>,
    userRequest?: GraphCacheResolver<WithTypename<Query>, QueryUserRequestArgs, WithTypename<UserRequest> | string>
  },
  Admin?: {
    admins?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Array<WithTypename<User> | string>>,
    allTeams?: GraphCacheResolver<WithTypename<Admin>, AdminAllTeamsArgs, Array<WithTypename<Team> | string>>,
    allUsers?: GraphCacheResolver<WithTypename<Admin>, AdminAllUsersArgs, Array<WithTypename<User> | string>>,
    collectionCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminCollectionCountInTeamArgs, Scalars['Int'] | string>,
    environmentCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminEnvironmentCountInTeamArgs, Scalars['Int'] | string>,
    invitedUsers?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Array<WithTypename<InvitedUser> | string>>,
    membersCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminMembersCountInTeamArgs, Scalars['Int'] | string>,
    pendingInvitationCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminPendingInvitationCountInTeamArgs, Array<WithTypename<TeamInvitation> | string>>,
    requestCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminRequestCountInTeamArgs, Scalars['Int'] | string>,
    teamCollectionsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    teamInfo?: GraphCacheResolver<WithTypename<Admin>, AdminTeamInfoArgs, WithTypename<Team> | string>,
    teamRequestsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    teamsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    userInfo?: GraphCacheResolver<WithTypename<Admin>, AdminUserInfoArgs, WithTypename<User> | string>,
    usersCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>
  },
  CollectionReorderData?: {
    collection?: GraphCacheResolver<WithTypename<CollectionReorderData>, Record<string, never>, WithTypename<TeamCollection> | string>,
    nextCollection?: GraphCacheResolver<WithTypename<CollectionReorderData>, Record<string, never>, WithTypename<TeamCollection> | string>
  },
  InvitedUser?: {
    adminEmail?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['String'] | string>,
    adminUid?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['ID'] | string>,
    invitedOn?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['DateTime'] | string>,
    inviteeEmail?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['String'] | string>
  },
  RequestReorderData?: {
    nextRequest?: GraphCacheResolver<WithTypename<RequestReorderData>, Record<string, never>, WithTypename<TeamRequest> | string>,
    request?: GraphCacheResolver<WithTypename<RequestReorderData>, Record<string, never>, WithTypename<TeamRequest> | string>
  },
  Shortcode?: {
    createdOn?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['String'] | string>
  },
  Team?: {
    editorsCount?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['ID'] | string>,
    members?: GraphCacheResolver<WithTypename<Team>, TeamMembersArgs, Array<WithTypename<TeamMember> | string>>,
    myRole?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, TeamMemberRole | string>,
    name?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['String'] | string>,
    ownersCount?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['Int'] | string>,
    teamEnvironments?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Array<WithTypename<TeamEnvironment> | string>>,
    teamInvitations?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Array<WithTypename<TeamInvitation> | string>>,
    teamMembers?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Array<WithTypename<TeamMember> | string>>,
    viewersCount?: GraphCacheResolver<WithTypename<Team>, Record<string, never>, Scalars['Int'] | string>
  },
  TeamCollection?: {
    children?: GraphCacheResolver<WithTypename<TeamCollection>, TeamCollectionChildrenArgs, Array<WithTypename<TeamCollection> | string>>,
    id?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, Scalars['ID'] | string>,
    parent?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, WithTypename<TeamCollection> | string>,
    parentID?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, Scalars['ID'] | string>,
    team?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, WithTypename<Team> | string>,
    title?: GraphCacheResolver<WithTypename<TeamCollection>, Record<string, never>, Scalars['String'] | string>
  },
  TeamEnvironment?: {
    id?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['String'] | string>,
    teamID?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    variables?: GraphCacheResolver<WithTypename<TeamEnvironment>, Record<string, never>, Scalars['String'] | string>
  },
  TeamInvitation?: {
    creator?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, WithTypename<User> | string>,
    creatorUid?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>,
    inviteeEmail?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['String'] | string>,
    inviteeRole?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, TeamMemberRole | string>,
    team?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, WithTypename<Team> | string>,
    teamID?: GraphCacheResolver<WithTypename<TeamInvitation>, Record<string, never>, Scalars['ID'] | string>
  },
  TeamMember?: {
    membershipID?: GraphCacheResolver<WithTypename<TeamMember>, Record<string, never>, Scalars['ID'] | string>,
    role?: GraphCacheResolver<WithTypename<TeamMember>, Record<string, never>, TeamMemberRole | string>,
    user?: GraphCacheResolver<WithTypename<TeamMember>, Record<string, never>, WithTypename<User> | string>
  },
  TeamRequest?: {
    collection?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, WithTypename<TeamCollection> | string>,
    collectionID?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['String'] | string>,
    team?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, WithTypename<Team> | string>,
    teamID?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['ID'] | string>,
    title?: GraphCacheResolver<WithTypename<TeamRequest>, Record<string, never>, Scalars['String'] | string>
  },
  User?: {
    GQLHistory?: GraphCacheResolver<WithTypename<User>, UserGqlHistoryArgs, Array<WithTypename<UserHistory> | string>>,
    RESTHistory?: GraphCacheResolver<WithTypename<User>, UserRestHistoryArgs, Array<WithTypename<UserHistory> | string>>,
    createdOn?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['DateTime'] | string>,
    currentGQLSession?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    currentRESTSession?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    displayName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    environments?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<UserEnvironment> | string>>,
    globalEnvironments?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<UserEnvironment> | string>,
    isAdmin?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>,
    photoURL?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    settings?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<UserSettings> | string>,
    uid?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['ID'] | string>
  },
  UserCollection?: {
    childrenGQL?: GraphCacheResolver<WithTypename<UserCollection>, UserCollectionChildrenGqlArgs, Array<WithTypename<UserCollection> | string>>,
    childrenREST?: GraphCacheResolver<WithTypename<UserCollection>, UserCollectionChildrenRestArgs, Array<WithTypename<UserCollection> | string>>,
    id?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, Scalars['ID'] | string>,
    parent?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, WithTypename<UserCollection> | string>,
    requests?: GraphCacheResolver<WithTypename<UserCollection>, UserCollectionRequestsArgs, Array<WithTypename<UserRequest> | string>>,
    title?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, ReqType | string>,
    user?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, WithTypename<User> | string>
  },
  UserCollectionExportJSONData?: {
    collectionType?: GraphCacheResolver<WithTypename<UserCollectionExportJsonData>, Record<string, never>, ReqType | string>,
    exportedCollection?: GraphCacheResolver<WithTypename<UserCollectionExportJsonData>, Record<string, never>, Scalars['ID'] | string>
  },
  UserCollectionRemovedData?: {
    id?: GraphCacheResolver<WithTypename<UserCollectionRemovedData>, Record<string, never>, Scalars['ID'] | string>,
    type?: GraphCacheResolver<WithTypename<UserCollectionRemovedData>, Record<string, never>, ReqType | string>
  },
  UserCollectionReorderData?: {
    nextUserCollection?: GraphCacheResolver<WithTypename<UserCollectionReorderData>, Record<string, never>, WithTypename<UserCollection> | string>,
    userCollection?: GraphCacheResolver<WithTypename<UserCollectionReorderData>, Record<string, never>, WithTypename<UserCollection> | string>
  },
  UserEnvironment?: {
    id?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    isGlobal?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['String'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    variables?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['String'] | string>
  },
  UserHistory?: {
    executedOn?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['ID'] | string>,
    isStarred?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['Boolean'] | string>,
    reqType?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, ReqType | string>,
    request?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['String'] | string>,
    responseMetadata?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['String'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['ID'] | string>
  },
  UserHistoryDeletedManyData?: {
    count?: GraphCacheResolver<WithTypename<UserHistoryDeletedManyData>, Record<string, never>, Scalars['Int'] | string>,
    reqType?: GraphCacheResolver<WithTypename<UserHistoryDeletedManyData>, Record<string, never>, ReqType | string>
  },
  UserRequest?: {
    collectionID?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['ID'] | string>,
    createdOn?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, ReqType | string>,
    user?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, WithTypename<User> | string>
  },
  UserRequestReorderData?: {
    nextRequest?: GraphCacheResolver<WithTypename<UserRequestReorderData>, Record<string, never>, WithTypename<UserRequest> | string>,
    request?: GraphCacheResolver<WithTypename<UserRequestReorderData>, Record<string, never>, WithTypename<UserRequest> | string>
  },
  UserSettings?: {
    id?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['ID'] | string>,
    properties?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['String'] | string>,
    updatedOn?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['DateTime'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['ID'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  acceptTeamInvitation?: GraphCacheOptimisticMutationResolver<MutationAcceptTeamInvitationArgs, WithTypename<TeamMember>>,
  addUserToTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationAddUserToTeamByAdminArgs, WithTypename<TeamMember>>,
  changeUserRoleInTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationChangeUserRoleInTeamByAdminArgs, WithTypename<TeamMember>>,
  clearGlobalEnvironments?: GraphCacheOptimisticMutationResolver<MutationClearGlobalEnvironmentsArgs, WithTypename<UserEnvironment>>,
  createChildCollection?: GraphCacheOptimisticMutationResolver<MutationCreateChildCollectionArgs, WithTypename<TeamCollection>>,
  createDuplicateEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateDuplicateEnvironmentArgs, WithTypename<TeamEnvironment>>,
  createGQLChildUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateGqlChildUserCollectionArgs, WithTypename<UserCollection>>,
  createGQLRootUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateGqlRootUserCollectionArgs, WithTypename<UserCollection>>,
  createGQLUserRequest?: GraphCacheOptimisticMutationResolver<MutationCreateGqlUserRequestArgs, WithTypename<UserRequest>>,
  createRESTChildUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRestChildUserCollectionArgs, WithTypename<UserCollection>>,
  createRESTRootUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRestRootUserCollectionArgs, WithTypename<UserCollection>>,
  createRESTUserRequest?: GraphCacheOptimisticMutationResolver<MutationCreateRestUserRequestArgs, WithTypename<UserRequest>>,
  createRequestInCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRequestInCollectionArgs, WithTypename<TeamRequest>>,
  createRootCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRootCollectionArgs, WithTypename<TeamCollection>>,
  createShortcode?: GraphCacheOptimisticMutationResolver<MutationCreateShortcodeArgs, WithTypename<Shortcode>>,
  createTeam?: GraphCacheOptimisticMutationResolver<MutationCreateTeamArgs, WithTypename<Team>>,
  createTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationCreateTeamByAdminArgs, WithTypename<Team>>,
  createTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateTeamEnvironmentArgs, WithTypename<TeamEnvironment>>,
  createTeamInvitation?: GraphCacheOptimisticMutationResolver<MutationCreateTeamInvitationArgs, WithTypename<TeamInvitation>>,
  createUserEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateUserEnvironmentArgs, WithTypename<UserEnvironment>>,
  createUserGlobalEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateUserGlobalEnvironmentArgs, WithTypename<UserEnvironment>>,
  createUserHistory?: GraphCacheOptimisticMutationResolver<MutationCreateUserHistoryArgs, WithTypename<UserHistory>>,
  createUserSettings?: GraphCacheOptimisticMutationResolver<MutationCreateUserSettingsArgs, WithTypename<UserSettings>>,
  deleteAllUserHistory?: GraphCacheOptimisticMutationResolver<MutationDeleteAllUserHistoryArgs, WithTypename<UserHistoryDeletedManyData>>,
  deleteAllVariablesFromTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteAllVariablesFromTeamEnvironmentArgs, WithTypename<TeamEnvironment>>,
  deleteCollection?: GraphCacheOptimisticMutationResolver<MutationDeleteCollectionArgs, Scalars['Boolean']>,
  deleteRequest?: GraphCacheOptimisticMutationResolver<MutationDeleteRequestArgs, Scalars['Boolean']>,
  deleteTeam?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamArgs, Scalars['Boolean']>,
  deleteTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamByAdminArgs, Scalars['Boolean']>,
  deleteTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamEnvironmentArgs, Scalars['Boolean']>,
  deleteUser?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Boolean']>,
  deleteUserCollection?: GraphCacheOptimisticMutationResolver<MutationDeleteUserCollectionArgs, Scalars['Boolean']>,
  deleteUserEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteUserEnvironmentArgs, Scalars['Boolean']>,
  deleteUserEnvironments?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Int']>,
  deleteUserRequest?: GraphCacheOptimisticMutationResolver<MutationDeleteUserRequestArgs, Scalars['Boolean']>,
  importCollectionsFromJSON?: GraphCacheOptimisticMutationResolver<MutationImportCollectionsFromJsonArgs, Scalars['Boolean']>,
  importUserCollectionsFromJSON?: GraphCacheOptimisticMutationResolver<MutationImportUserCollectionsFromJsonArgs, Scalars['Boolean']>,
  inviteNewUser?: GraphCacheOptimisticMutationResolver<MutationInviteNewUserArgs, WithTypename<InvitedUser>>,
  leaveTeam?: GraphCacheOptimisticMutationResolver<MutationLeaveTeamArgs, Scalars['Boolean']>,
  makeUserAdmin?: GraphCacheOptimisticMutationResolver<MutationMakeUserAdminArgs, Scalars['Boolean']>,
  moveCollection?: GraphCacheOptimisticMutationResolver<MutationMoveCollectionArgs, WithTypename<TeamCollection>>,
  moveRequest?: GraphCacheOptimisticMutationResolver<MutationMoveRequestArgs, WithTypename<TeamRequest>>,
  moveUserCollection?: GraphCacheOptimisticMutationResolver<MutationMoveUserCollectionArgs, WithTypename<UserCollection>>,
  moveUserRequest?: GraphCacheOptimisticMutationResolver<MutationMoveUserRequestArgs, WithTypename<UserRequest>>,
  removeRequestFromHistory?: GraphCacheOptimisticMutationResolver<MutationRemoveRequestFromHistoryArgs, WithTypename<UserHistory>>,
  removeTeamMember?: GraphCacheOptimisticMutationResolver<MutationRemoveTeamMemberArgs, Scalars['Boolean']>,
  removeUserAsAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserAsAdminArgs, Scalars['Boolean']>,
  removeUserByAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserByAdminArgs, Scalars['Boolean']>,
  removeUserFromTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserFromTeamByAdminArgs, Scalars['Boolean']>,
  renameCollection?: GraphCacheOptimisticMutationResolver<MutationRenameCollectionArgs, WithTypename<TeamCollection>>,
  renameTeam?: GraphCacheOptimisticMutationResolver<MutationRenameTeamArgs, WithTypename<Team>>,
  renameTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationRenameTeamByAdminArgs, WithTypename<Team>>,
  renameUserCollection?: GraphCacheOptimisticMutationResolver<MutationRenameUserCollectionArgs, WithTypename<UserCollection>>,
  replaceCollectionsWithJSON?: GraphCacheOptimisticMutationResolver<MutationReplaceCollectionsWithJsonArgs, Scalars['Boolean']>,
  revokeShortcode?: GraphCacheOptimisticMutationResolver<MutationRevokeShortcodeArgs, Scalars['Boolean']>,
  revokeTeamInvitation?: GraphCacheOptimisticMutationResolver<MutationRevokeTeamInvitationArgs, Scalars['Boolean']>,
  toggleHistoryStarStatus?: GraphCacheOptimisticMutationResolver<MutationToggleHistoryStarStatusArgs, WithTypename<UserHistory>>,
  updateCollectionOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateCollectionOrderArgs, Scalars['Boolean']>,
  updateGQLUserRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateGqlUserRequestArgs, WithTypename<UserRequest>>,
  updateLookUpRequestOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateLookUpRequestOrderArgs, Scalars['Boolean']>,
  updateRESTUserRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateRestUserRequestArgs, WithTypename<UserRequest>>,
  updateRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateRequestArgs, WithTypename<TeamRequest>>,
  updateTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationUpdateTeamEnvironmentArgs, WithTypename<TeamEnvironment>>,
  updateTeamMemberRole?: GraphCacheOptimisticMutationResolver<MutationUpdateTeamMemberRoleArgs, WithTypename<TeamMember>>,
  updateUserCollectionOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateUserCollectionOrderArgs, Scalars['Boolean']>,
  updateUserEnvironment?: GraphCacheOptimisticMutationResolver<MutationUpdateUserEnvironmentArgs, WithTypename<UserEnvironment>>,
  updateUserSessions?: GraphCacheOptimisticMutationResolver<MutationUpdateUserSessionsArgs, WithTypename<User>>,
  updateUserSettings?: GraphCacheOptimisticMutationResolver<MutationUpdateUserSettingsArgs, WithTypename<UserSettings>>
};

export type GraphCacheUpdaters = {
  Mutation?: {
    acceptTeamInvitation?: GraphCacheUpdateResolver<{ acceptTeamInvitation: WithTypename<TeamMember> }, MutationAcceptTeamInvitationArgs>,
    addUserToTeamByAdmin?: GraphCacheUpdateResolver<{ addUserToTeamByAdmin: WithTypename<TeamMember> }, MutationAddUserToTeamByAdminArgs>,
    changeUserRoleInTeamByAdmin?: GraphCacheUpdateResolver<{ changeUserRoleInTeamByAdmin: WithTypename<TeamMember> }, MutationChangeUserRoleInTeamByAdminArgs>,
    clearGlobalEnvironments?: GraphCacheUpdateResolver<{ clearGlobalEnvironments: WithTypename<UserEnvironment> }, MutationClearGlobalEnvironmentsArgs>,
    createChildCollection?: GraphCacheUpdateResolver<{ createChildCollection: WithTypename<TeamCollection> }, MutationCreateChildCollectionArgs>,
    createDuplicateEnvironment?: GraphCacheUpdateResolver<{ createDuplicateEnvironment: WithTypename<TeamEnvironment> }, MutationCreateDuplicateEnvironmentArgs>,
    createGQLChildUserCollection?: GraphCacheUpdateResolver<{ createGQLChildUserCollection: WithTypename<UserCollection> }, MutationCreateGqlChildUserCollectionArgs>,
    createGQLRootUserCollection?: GraphCacheUpdateResolver<{ createGQLRootUserCollection: WithTypename<UserCollection> }, MutationCreateGqlRootUserCollectionArgs>,
    createGQLUserRequest?: GraphCacheUpdateResolver<{ createGQLUserRequest: WithTypename<UserRequest> }, MutationCreateGqlUserRequestArgs>,
    createRESTChildUserCollection?: GraphCacheUpdateResolver<{ createRESTChildUserCollection: WithTypename<UserCollection> }, MutationCreateRestChildUserCollectionArgs>,
    createRESTRootUserCollection?: GraphCacheUpdateResolver<{ createRESTRootUserCollection: WithTypename<UserCollection> }, MutationCreateRestRootUserCollectionArgs>,
    createRESTUserRequest?: GraphCacheUpdateResolver<{ createRESTUserRequest: WithTypename<UserRequest> }, MutationCreateRestUserRequestArgs>,
    createRequestInCollection?: GraphCacheUpdateResolver<{ createRequestInCollection: WithTypename<TeamRequest> }, MutationCreateRequestInCollectionArgs>,
    createRootCollection?: GraphCacheUpdateResolver<{ createRootCollection: WithTypename<TeamCollection> }, MutationCreateRootCollectionArgs>,
    createShortcode?: GraphCacheUpdateResolver<{ createShortcode: WithTypename<Shortcode> }, MutationCreateShortcodeArgs>,
    createTeam?: GraphCacheUpdateResolver<{ createTeam: WithTypename<Team> }, MutationCreateTeamArgs>,
    createTeamByAdmin?: GraphCacheUpdateResolver<{ createTeamByAdmin: WithTypename<Team> }, MutationCreateTeamByAdminArgs>,
    createTeamEnvironment?: GraphCacheUpdateResolver<{ createTeamEnvironment: WithTypename<TeamEnvironment> }, MutationCreateTeamEnvironmentArgs>,
    createTeamInvitation?: GraphCacheUpdateResolver<{ createTeamInvitation: WithTypename<TeamInvitation> }, MutationCreateTeamInvitationArgs>,
    createUserEnvironment?: GraphCacheUpdateResolver<{ createUserEnvironment: WithTypename<UserEnvironment> }, MutationCreateUserEnvironmentArgs>,
    createUserGlobalEnvironment?: GraphCacheUpdateResolver<{ createUserGlobalEnvironment: WithTypename<UserEnvironment> }, MutationCreateUserGlobalEnvironmentArgs>,
    createUserHistory?: GraphCacheUpdateResolver<{ createUserHistory: WithTypename<UserHistory> }, MutationCreateUserHistoryArgs>,
    createUserSettings?: GraphCacheUpdateResolver<{ createUserSettings: WithTypename<UserSettings> }, MutationCreateUserSettingsArgs>,
    deleteAllUserHistory?: GraphCacheUpdateResolver<{ deleteAllUserHistory: WithTypename<UserHistoryDeletedManyData> }, MutationDeleteAllUserHistoryArgs>,
    deleteAllVariablesFromTeamEnvironment?: GraphCacheUpdateResolver<{ deleteAllVariablesFromTeamEnvironment: WithTypename<TeamEnvironment> }, MutationDeleteAllVariablesFromTeamEnvironmentArgs>,
    deleteCollection?: GraphCacheUpdateResolver<{ deleteCollection: Scalars['Boolean'] }, MutationDeleteCollectionArgs>,
    deleteRequest?: GraphCacheUpdateResolver<{ deleteRequest: Scalars['Boolean'] }, MutationDeleteRequestArgs>,
    deleteTeam?: GraphCacheUpdateResolver<{ deleteTeam: Scalars['Boolean'] }, MutationDeleteTeamArgs>,
    deleteTeamByAdmin?: GraphCacheUpdateResolver<{ deleteTeamByAdmin: Scalars['Boolean'] }, MutationDeleteTeamByAdminArgs>,
    deleteTeamEnvironment?: GraphCacheUpdateResolver<{ deleteTeamEnvironment: Scalars['Boolean'] }, MutationDeleteTeamEnvironmentArgs>,
    deleteUser?: GraphCacheUpdateResolver<{ deleteUser: Scalars['Boolean'] }, Record<string, never>>,
    deleteUserCollection?: GraphCacheUpdateResolver<{ deleteUserCollection: Scalars['Boolean'] }, MutationDeleteUserCollectionArgs>,
    deleteUserEnvironment?: GraphCacheUpdateResolver<{ deleteUserEnvironment: Scalars['Boolean'] }, MutationDeleteUserEnvironmentArgs>,
    deleteUserEnvironments?: GraphCacheUpdateResolver<{ deleteUserEnvironments: Scalars['Int'] }, Record<string, never>>,
    deleteUserRequest?: GraphCacheUpdateResolver<{ deleteUserRequest: Scalars['Boolean'] }, MutationDeleteUserRequestArgs>,
    importCollectionsFromJSON?: GraphCacheUpdateResolver<{ importCollectionsFromJSON: Scalars['Boolean'] }, MutationImportCollectionsFromJsonArgs>,
    importUserCollectionsFromJSON?: GraphCacheUpdateResolver<{ importUserCollectionsFromJSON: Scalars['Boolean'] }, MutationImportUserCollectionsFromJsonArgs>,
    inviteNewUser?: GraphCacheUpdateResolver<{ inviteNewUser: WithTypename<InvitedUser> }, MutationInviteNewUserArgs>,
    leaveTeam?: GraphCacheUpdateResolver<{ leaveTeam: Scalars['Boolean'] }, MutationLeaveTeamArgs>,
    makeUserAdmin?: GraphCacheUpdateResolver<{ makeUserAdmin: Scalars['Boolean'] }, MutationMakeUserAdminArgs>,
    moveCollection?: GraphCacheUpdateResolver<{ moveCollection: WithTypename<TeamCollection> }, MutationMoveCollectionArgs>,
    moveRequest?: GraphCacheUpdateResolver<{ moveRequest: WithTypename<TeamRequest> }, MutationMoveRequestArgs>,
    moveUserCollection?: GraphCacheUpdateResolver<{ moveUserCollection: WithTypename<UserCollection> }, MutationMoveUserCollectionArgs>,
    moveUserRequest?: GraphCacheUpdateResolver<{ moveUserRequest: WithTypename<UserRequest> }, MutationMoveUserRequestArgs>,
    removeRequestFromHistory?: GraphCacheUpdateResolver<{ removeRequestFromHistory: WithTypename<UserHistory> }, MutationRemoveRequestFromHistoryArgs>,
    removeTeamMember?: GraphCacheUpdateResolver<{ removeTeamMember: Scalars['Boolean'] }, MutationRemoveTeamMemberArgs>,
    removeUserAsAdmin?: GraphCacheUpdateResolver<{ removeUserAsAdmin: Scalars['Boolean'] }, MutationRemoveUserAsAdminArgs>,
    removeUserByAdmin?: GraphCacheUpdateResolver<{ removeUserByAdmin: Scalars['Boolean'] }, MutationRemoveUserByAdminArgs>,
    removeUserFromTeamByAdmin?: GraphCacheUpdateResolver<{ removeUserFromTeamByAdmin: Scalars['Boolean'] }, MutationRemoveUserFromTeamByAdminArgs>,
    renameCollection?: GraphCacheUpdateResolver<{ renameCollection: WithTypename<TeamCollection> }, MutationRenameCollectionArgs>,
    renameTeam?: GraphCacheUpdateResolver<{ renameTeam: WithTypename<Team> }, MutationRenameTeamArgs>,
    renameTeamByAdmin?: GraphCacheUpdateResolver<{ renameTeamByAdmin: WithTypename<Team> }, MutationRenameTeamByAdminArgs>,
    renameUserCollection?: GraphCacheUpdateResolver<{ renameUserCollection: WithTypename<UserCollection> }, MutationRenameUserCollectionArgs>,
    replaceCollectionsWithJSON?: GraphCacheUpdateResolver<{ replaceCollectionsWithJSON: Scalars['Boolean'] }, MutationReplaceCollectionsWithJsonArgs>,
    revokeShortcode?: GraphCacheUpdateResolver<{ revokeShortcode: Scalars['Boolean'] }, MutationRevokeShortcodeArgs>,
    revokeTeamInvitation?: GraphCacheUpdateResolver<{ revokeTeamInvitation: Scalars['Boolean'] }, MutationRevokeTeamInvitationArgs>,
    toggleHistoryStarStatus?: GraphCacheUpdateResolver<{ toggleHistoryStarStatus: WithTypename<UserHistory> }, MutationToggleHistoryStarStatusArgs>,
    updateCollectionOrder?: GraphCacheUpdateResolver<{ updateCollectionOrder: Scalars['Boolean'] }, MutationUpdateCollectionOrderArgs>,
    updateGQLUserRequest?: GraphCacheUpdateResolver<{ updateGQLUserRequest: WithTypename<UserRequest> }, MutationUpdateGqlUserRequestArgs>,
    updateLookUpRequestOrder?: GraphCacheUpdateResolver<{ updateLookUpRequestOrder: Scalars['Boolean'] }, MutationUpdateLookUpRequestOrderArgs>,
    updateRESTUserRequest?: GraphCacheUpdateResolver<{ updateRESTUserRequest: WithTypename<UserRequest> }, MutationUpdateRestUserRequestArgs>,
    updateRequest?: GraphCacheUpdateResolver<{ updateRequest: WithTypename<TeamRequest> }, MutationUpdateRequestArgs>,
    updateTeamEnvironment?: GraphCacheUpdateResolver<{ updateTeamEnvironment: WithTypename<TeamEnvironment> }, MutationUpdateTeamEnvironmentArgs>,
    updateTeamMemberRole?: GraphCacheUpdateResolver<{ updateTeamMemberRole: WithTypename<TeamMember> }, MutationUpdateTeamMemberRoleArgs>,
    updateUserCollectionOrder?: GraphCacheUpdateResolver<{ updateUserCollectionOrder: Scalars['Boolean'] }, MutationUpdateUserCollectionOrderArgs>,
    updateUserEnvironment?: GraphCacheUpdateResolver<{ updateUserEnvironment: WithTypename<UserEnvironment> }, MutationUpdateUserEnvironmentArgs>,
    updateUserSessions?: GraphCacheUpdateResolver<{ updateUserSessions: WithTypename<User> }, MutationUpdateUserSessionsArgs>,
    updateUserSettings?: GraphCacheUpdateResolver<{ updateUserSettings: WithTypename<UserSettings> }, MutationUpdateUserSettingsArgs>
  },
  Subscription?: {
    collectionOrderUpdated?: GraphCacheUpdateResolver<{ collectionOrderUpdated: WithTypename<CollectionReorderData> }, SubscriptionCollectionOrderUpdatedArgs>,
    myShortcodesCreated?: GraphCacheUpdateResolver<{ myShortcodesCreated: WithTypename<Shortcode> }, Record<string, never>>,
    myShortcodesRevoked?: GraphCacheUpdateResolver<{ myShortcodesRevoked: WithTypename<Shortcode> }, Record<string, never>>,
    requestMoved?: GraphCacheUpdateResolver<{ requestMoved: WithTypename<TeamRequest> }, SubscriptionRequestMovedArgs>,
    requestOrderUpdated?: GraphCacheUpdateResolver<{ requestOrderUpdated: WithTypename<RequestReorderData> }, SubscriptionRequestOrderUpdatedArgs>,
    teamCollectionAdded?: GraphCacheUpdateResolver<{ teamCollectionAdded: WithTypename<TeamCollection> }, SubscriptionTeamCollectionAddedArgs>,
    teamCollectionMoved?: GraphCacheUpdateResolver<{ teamCollectionMoved: WithTypename<TeamCollection> }, SubscriptionTeamCollectionMovedArgs>,
    teamCollectionRemoved?: GraphCacheUpdateResolver<{ teamCollectionRemoved: Scalars['ID'] }, SubscriptionTeamCollectionRemovedArgs>,
    teamCollectionUpdated?: GraphCacheUpdateResolver<{ teamCollectionUpdated: WithTypename<TeamCollection> }, SubscriptionTeamCollectionUpdatedArgs>,
    teamEnvironmentCreated?: GraphCacheUpdateResolver<{ teamEnvironmentCreated: WithTypename<TeamEnvironment> }, SubscriptionTeamEnvironmentCreatedArgs>,
    teamEnvironmentDeleted?: GraphCacheUpdateResolver<{ teamEnvironmentDeleted: WithTypename<TeamEnvironment> }, SubscriptionTeamEnvironmentDeletedArgs>,
    teamEnvironmentUpdated?: GraphCacheUpdateResolver<{ teamEnvironmentUpdated: WithTypename<TeamEnvironment> }, SubscriptionTeamEnvironmentUpdatedArgs>,
    teamInvitationAdded?: GraphCacheUpdateResolver<{ teamInvitationAdded: WithTypename<TeamInvitation> }, SubscriptionTeamInvitationAddedArgs>,
    teamInvitationRemoved?: GraphCacheUpdateResolver<{ teamInvitationRemoved: Scalars['ID'] }, SubscriptionTeamInvitationRemovedArgs>,
    teamMemberAdded?: GraphCacheUpdateResolver<{ teamMemberAdded: WithTypename<TeamMember> }, SubscriptionTeamMemberAddedArgs>,
    teamMemberRemoved?: GraphCacheUpdateResolver<{ teamMemberRemoved: Scalars['ID'] }, SubscriptionTeamMemberRemovedArgs>,
    teamMemberUpdated?: GraphCacheUpdateResolver<{ teamMemberUpdated: WithTypename<TeamMember> }, SubscriptionTeamMemberUpdatedArgs>,
    teamRequestAdded?: GraphCacheUpdateResolver<{ teamRequestAdded: WithTypename<TeamRequest> }, SubscriptionTeamRequestAddedArgs>,
    teamRequestDeleted?: GraphCacheUpdateResolver<{ teamRequestDeleted: Scalars['ID'] }, SubscriptionTeamRequestDeletedArgs>,
    teamRequestUpdated?: GraphCacheUpdateResolver<{ teamRequestUpdated: WithTypename<TeamRequest> }, SubscriptionTeamRequestUpdatedArgs>,
    userCollectionCreated?: GraphCacheUpdateResolver<{ userCollectionCreated: WithTypename<UserCollection> }, Record<string, never>>,
    userCollectionMoved?: GraphCacheUpdateResolver<{ userCollectionMoved: WithTypename<UserCollection> }, Record<string, never>>,
    userCollectionOrderUpdated?: GraphCacheUpdateResolver<{ userCollectionOrderUpdated: WithTypename<UserCollectionReorderData> }, Record<string, never>>,
    userCollectionRemoved?: GraphCacheUpdateResolver<{ userCollectionRemoved: WithTypename<UserCollectionRemovedData> }, Record<string, never>>,
    userCollectionUpdated?: GraphCacheUpdateResolver<{ userCollectionUpdated: WithTypename<UserCollection> }, Record<string, never>>,
    userDeleted?: GraphCacheUpdateResolver<{ userDeleted: WithTypename<User> }, Record<string, never>>,
    userEnvironmentCreated?: GraphCacheUpdateResolver<{ userEnvironmentCreated: WithTypename<UserEnvironment> }, Record<string, never>>,
    userEnvironmentDeleteMany?: GraphCacheUpdateResolver<{ userEnvironmentDeleteMany: Scalars['Int'] }, Record<string, never>>,
    userEnvironmentDeleted?: GraphCacheUpdateResolver<{ userEnvironmentDeleted: WithTypename<UserEnvironment> }, Record<string, never>>,
    userEnvironmentUpdated?: GraphCacheUpdateResolver<{ userEnvironmentUpdated: WithTypename<UserEnvironment> }, Record<string, never>>,
    userHistoryCreated?: GraphCacheUpdateResolver<{ userHistoryCreated: WithTypename<UserHistory> }, Record<string, never>>,
    userHistoryDeleted?: GraphCacheUpdateResolver<{ userHistoryDeleted: WithTypename<UserHistory> }, Record<string, never>>,
    userHistoryDeletedMany?: GraphCacheUpdateResolver<{ userHistoryDeletedMany: WithTypename<UserHistoryDeletedManyData> }, Record<string, never>>,
    userHistoryUpdated?: GraphCacheUpdateResolver<{ userHistoryUpdated: WithTypename<UserHistory> }, Record<string, never>>,
    userInvited?: GraphCacheUpdateResolver<{ userInvited: WithTypename<InvitedUser> }, Record<string, never>>,
    userRequestCreated?: GraphCacheUpdateResolver<{ userRequestCreated: WithTypename<UserRequest> }, Record<string, never>>,
    userRequestDeleted?: GraphCacheUpdateResolver<{ userRequestDeleted: WithTypename<UserRequest> }, Record<string, never>>,
    userRequestMoved?: GraphCacheUpdateResolver<{ userRequestMoved: WithTypename<UserRequestReorderData> }, Record<string, never>>,
    userRequestUpdated?: GraphCacheUpdateResolver<{ userRequestUpdated: WithTypename<UserRequest> }, Record<string, never>>,
    userSettingsCreated?: GraphCacheUpdateResolver<{ userSettingsCreated: WithTypename<UserSettings> }, Record<string, never>>,
    userSettingsUpdated?: GraphCacheUpdateResolver<{ userSettingsUpdated: WithTypename<UserSettings> }, Record<string, never>>,
    userUpdated?: GraphCacheUpdateResolver<{ userUpdated: WithTypename<User> }, Record<string, never>>
  },
};

export type GraphCacheConfig = {
  schema?: IntrospectionData,
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
  storage?: GraphCacheStorageAdapter
};